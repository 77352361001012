const routes = [
  {
    index: true,
    label: 'Jen Liu',
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Resume',
    path: '/resume',
  },
  {
    label: 'Projects',
    path: '/projects',
  },
  // {
  //   label: 'Research',
  //   path: '/research',
  // },
];

export default routes;
